import React, { useState } from "react"

// import Layout from "components/Layout"
import schoolImg from "assets/images/schoolImg.svg"
import familyImg from "assets/images/familyImg.svg"
import groupImg from "assets/images/groupImg.svg"
import { FilloutPopupEmbed } from "@fillout/react"
import teacherImg from "assets/images/teacherImg.svg"
import arrowRight from "assets/images/icons/arrow-right.svg"
// import SEO from "components/Seo"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { Link } from "gatsby"
import "@fillout/react/style.css"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
// const Layout = lazy(() => import("../components/Layout")

const Price = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState(0)
  // const [selectedListIndex, setSelectedListIndex] = React.useState()
  const [isSelectStudent, setSelectStudent] = React.useState(false)
  const [numberOfStudent, setNumberOfStudent] = React.useState(1)
  const [numberOfYear, setNumberOfYear] = React.useState(1)
  // const [listIndex, setListIndex] = React.useState([])

  // const handleParaClick = (index, ...defaultListIndex) => {
  //   setSelectedQuestionIndex(selectedQuestionIndex === index ? 0 : index)
  //   setListIndex(defaultListIndex)
  // }

  // const handleListClick = key => {
  //   const index = listIndex.indexOf(key)
  //   listIndex.includes(key) ? listIndex.splice(index, 1) : listIndex.push(key)
  //   setListIndex([...listIndex])
  // }

  const handleSelectUserType = e => {
    setSelectStudent(e.target.checked)
    setNumberOfStudent(100)
    setNumberOfYear(1)
    if (!e.target.checked) {
      setNumberOfStudent(1)
    }
  }

  const handleChangeStudentCount = student => {
    setNumberOfStudent(count => {
      // return isSelectStudent && count + student < 100 ? 100 : count + student ? count + student : 1
      return count + student ? count + student : 1
    })
  }
  const handleChangeYearCount = year => {
    setNumberOfYear(count => {
      return count + year ? count + year : 1
    })
  }

  const handleChangeStdInputTextCount = e => {
    // if (isSelectStudent && +e.target.value < 100) return setNumberOfStudent(100)
    if (+e.target.value) {
      setNumberOfStudent(+e.target.value)
    }
  }
  const handleChangeYearInputTextCount = e => {
    if (+e.target.value) {
      setNumberOfYear(+e.target.value)
    }
  }

  // {
  //   isSelectStudent ? (numberOfStudent * numberOfYear * 3 <= 1500 ? numberOfStudent * numberOfYear * 3 : 1500) : 0
  // }
  let totalPrice = 0
  if (isSelectStudent) {
    if (numberOfStudent * numberOfYear * 3 >= 1500) {
      totalPrice = 1500
    } else if (numberOfStudent * numberOfYear * 3 <= 300) {
      totalPrice = 300
    } else {
      totalPrice = numberOfStudent * numberOfYear * 3
    }
  }
  return (
    // <Suspense fallback={<div style={{ visibility: "hidden", height: "100vh" }}></div>}>
    <Layout>
      <SEO
        title="Pricing | MathFactLab"
        description="Learn about approaches to student math fact fluency."
        keywords={[
          `Pricing`,
          `Implement MathfactLab`,
          "Pricing math facts lab",
          `Math fact instruction`,
          `math fact strategies`,
          // added as per docs
          "Math Fact Families",
          "Multiplication Facts Practice",
          "Multiplication Facts",
          "Addition Facts",
          "Division Facts",
          "Double Facts Math",
          "Fact Family Multiplication",
          "Multiplication Math Facts",
          "Multiplication and Division Fact Families",
          "Multiplication Table Practice",
          "Subtraction Facts",
          "Addition Math Facts",
          "Basic Math Facts",
          "Division Fact Families",
          "Fact Triangles",
          "Math Fact Practice",
          "Addition and Subtraction Fact Family",
          "Double Plus One Fact",
        ]}
        lang="en-us"
        robots="index, follow"
        locales={["en-us"]}
      />

      <section className="mfl-spotlightInner-section section-background-2 section classroom-spotlight price-spotlightInner-banner">
        <div className="circle top">
          <span className="circle-green size-65"></span>
        </div>
        <div className="circle bottom">
          <span className="circle-green size-38"></span>
          <span className="circle-blue size-90"></span>
        </div>
        <div className="container">
          <div className="spotlightInner-content-wrapper">
            <div className="spotlightInner-content"></div>
            <div className="spotlightInner-image"></div>
          </div>
        </div>
      </section>

      <section className="price-spotlightInner-section">
        <div className="container">
          <div className="price-spotlightInner-content-wrapper">
            {/* <div className="spotlightInner-content">
              <h1 className="spotlightInner-title">Pricing</h1>
            </div> */}
            <div className="spotlightInner-image desktop">
              <div className="grid-container">
                {/* ///11111 */}
                <div className="grid-item price-card-1 nohover">
                  <div className="top-section"></div>
                  <div className="upper-section justify-center">
                    <div>
                      <img src={groupImg} className="" alt="groupImg" />
                    </div>
                    {/*   <div className="price-card-title">
                      <span>Pricing Calculator</span>
                    </div>
                   
                    <div className="switch-chip">
                      {isSelectStudent ? "Teacher/Parents" : <b>Teacher/Parents</b>}

                      <label className="switch">
                        <input type="checkbox" onChange={handleSelectUserType} checked={isSelectStudent} />
                        <span className="slider round"></span>
                      </label>
                      {isSelectStudent ? <b>School</b> : "School"}
                    </div>
                    <div>
                      <div className="mb-6">
                        <b>Number of Students</b>
                      </div>
                      <div className="counter-grid-container max-w-250">
                        <div className="left-counter" onClick={() => handleChangeStudentCount(-1)}>
                          <b>-</b>
                        </div>

                        <div className="count">
                          <input
                            value={numberOfStudent}
                            type="number"
                            onChange={e => handleChangeStdInputTextCount(e)}
                          />
                        </div>
                        <div className="right-counter" onClick={() => handleChangeStudentCount(1)}>
                          <b>+</b>
                        </div>
                      </div>
                    </div>

                    <div style={{ visibility: !isSelectStudent ? "hidden" : "initial" }}>
                      <div className="mb-6">
                        <b>Number of Years</b>
                      </div>
                      <div className="counter-grid-container  max-w-250">
                        <div className="left-counter" onClick={() => handleChangeYearCount(-1)}>
                          <b>-</b>
                        </div>
                        <div className="count">
                          {" "}
                          <input value={numberOfYear} type="number" onChange={e => handleChangeYearInputTextCount(e)} />
                        </div>
                        <div className="right-counter" onClick={() => handleChangeYearCount(1)}>
                          <b>+</b>
                        </div>
                      </div>
                    </div>
                    <div className="total-section  max-w-250">
                      <div>Total</div>
                      <div>
                        <b>${totalPrice}</b>
                      </div></div> */}
                  </div>

                  <div className="bottom-section border-outside">
                    <div className="feature bg-dark">
                      <b>Features </b>
                    </div>
                    <div className="feature">Number of Student Licenses </div>
                    <div className="feature">Additional Licenses</div>
                    <div className="feature two-line vertical-align-center justify-start">Additional Details</div>
                    <div className="feature">Available Student Levels</div>

                    <div className="feature three-line vertical-align-center justify-start">
                      Number of Adult Profiles
                    </div>
                    <div className="feature">Number of Classes</div>
                    <div className="feature three-line vertical-align-center justify-start">Reports</div>
                    <div className="feature three-line vertical-align-center justify-start">Google Integration</div>
                    <div className="feature">Teaching Tools</div>
                    <div className="feature">Payment by Purchase Order</div>
                    <div className="feature">Access for Co-Teachers</div>
                    <div className="feature">Student Single Sign-On</div>
                    <div className="feature">ClassLink Integration</div>
                    <div className="feature">Clever Integration </div>
                  </div>
                </div>
                {/* 1111111111 */}
                <div className="grid-item price-card-1 card-3">
                  <div className="top-section"></div>
                  <div className="upper-section align-center">
                    <div className="price-card-title plan-img text-center">
                      <img src={familyImg} className="" alt="familyImg" />
                    </div>
                    <div className="price-card-title color-purple text-center">Family Plan</div>
                    {/* For cross text put below classname className="diagonal-line-text purple" */}
                    <div className="purple">
                      <div className="text-center amount color-purple">
                        {/* <span className="small-text">Free until July 5 </span> */}
                        $15/year
                      </div>
                    </div>
                    {/* <div className="text-center mb-22 line-height-28">
                      <b className="pre-student mb-6">Per Student / Per Year</b>
                      <br />
                      Volume discounts available
                    </div> */}
                    {/* <Link
                      to="https://mathfactlab.helpscoutdocs.com/article/79-pricing-as-of-july-2024"
                      title="Future Pricing"
                      className="text-center mb-10 font-15 a-link-outline"
                      target="_blank"
                   
                    >
                      Learn more about future pricing.
                    </Link> */}
                    <Link
                      to="https://app.mathfactlab.com/signup"
                      title="Student Login"
                      rel="noreferrer"
                      className="button-bg-purple"
                      target="_blank"
                    >
                      <div
                        onClick={e => {
                          trackCustomEvent({
                            category: "Start Today",
                            action: "Click",
                            label: "Clicked on Start Today",
                          })
                        }}
                      >
                        Start Today
                      </div>
                    </Link>
                  </div>
                  <div className="bottom-section">
                    <div className="feature">
                      <span>as</span>
                    </div>
                    <div className="feature no-bg text-center">1</div>
                    <div className="feature no-bg text-center">$5</div>
                    <div className="feature no-bg text-center vertical-align-center two-line">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">All</div>
                    <div className="feature no-bg text-center vertical-align-center three-line">1 parent profile</div>
                    <div className="feature no-bg text-center">1</div>
                    <div className="feature no-bg text-center vertical-align-center three-line">
                      Onscreen monitoring of student usage and progress
                    </div>
                    <div className="feature no-bg text-center vertical-align-center three-line">
                      Parent Single Sign-On
                    </div>
                    {/* remove span to show X */}
                    <div className="feature no-bg text-center">
                      <span>X</span>
                    </div>
                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>
                  </div>
                </div>
                {/* 222222222 */}
                <div className="grid-item price-card-1">
                  <div className="top-section"></div>

                  <div className="upper-section align-center">
                    <div className="price-card-title plan-img text-center">
                      <img src={teacherImg} className="" alt="teacherImg" />
                    </div>
                    <div className="price-card-title color-blue text-center">Teacher Plan</div>
                    {/* diagonal-line-text */}
                    <div className=" blue">
                      <div className="text-center amount color-blue">
                        {/* <span className="small-text">Free until August 1 </span> */}
                        $75/year
                      </div>
                    </div>

                    {/* <div className="text-center ">$50 for teachers paying out of pocket</div> */}
                    {/* <Link
                      to="https://mathfactlab.helpscoutdocs.com/article/79-pricing-as-of-july-2024"
                      title="Future Pricing"
                      className="text-center mb-10 font-15 a-link-outline"
                      target="_blank"
                    >
                      Next school year’s pricing
                    </Link> */}
                    <Link
                      to="https://app.mathfactlab.com/signup"
                      title="Student Login"
                      rel="noreferrer"
                      className="button-bg-blue"
                      target="_blank"
                    >
                      <div
                        onClick={e => {
                          trackCustomEvent({
                            category: "Start Today",
                            action: "Click",
                            label: "Clicked on Start Today",
                          })
                        }}
                      >
                        Start Today
                      </div>
                    </Link>
                  </div>
                  <div className="bottom-section">
                    <div className="feature">
                      {" "}
                      <span>as</span>
                    </div>
                    <div className="feature no-bg text-center">50</div>
                    <div className="feature no-bg text-center">$1.50</div>
                    <div className="feature no-bg text-center vertical-align-center two-line">
                      33% off for teachers paying out-of-pocket
                    </div>
                    <div className="feature no-bg text-center">All</div>
                    <div className="feature no-bg text-center vertical-align-center three-line">1 teacher profile</div>
                    <div className="feature no-bg text-center">Up to 6</div>
                    <div className="feature no-bg text-center vertical-align-center three-line">
                      Everything from Family Plan plus downloadable individual and group reports (pdf or .csv)
                    </div>
                    <div className="feature no-bg text-center vertical-align-center three-line">
                      Teacher Single Sign-On and importing of class lists from Google Classroom
                    </div>
                    <div className="feature no-bg text-center">✔</div>
                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>
                    {/* remove span to show X */}
                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>

                    <div className="feature no-bg text-center">
                      {" "}
                      <span>X</span>
                    </div>
                  </div>
                </div>
                {/* 333333333333 */}
                <div className="grid-item price-card-1 card-2">
                  <div className="top-section"></div>
                  <div className="upper-section align-center">
                    <div className="price-card-title plan-img text-center">
                      <img src={schoolImg} className="" alt="schoolImg" />
                    </div>
                    <div className="price-card-title color-green text-center">School/District Plan</div>
                    <div className="text-center amount color-green">
                      {/* <span className="small-text">from </span> */}
                      $3/student
                    </div>
                    {/* <div className="text-center mb-22 line-height-28">
                      <b className="pre-student mb-6">Per Student / Per Year</b>
                      <br />
                      Volume discounts available
                    </div> */}
                    {/* <div className="text-center ">
                      {" "}
                      <ul className="bullet-list">
                        <li>$3 per additional student</li>
                        <li> Volume Discounts Available</li>
                      </ul>{" "}
                    </div> */}
                    {/* <Link
                      to="https://mathfactlab.helpscoutdocs.com/article/79-pricing-as-of-july-2024"
                      title="Future Pricing"
                      className="text-center mb-10 font-15 a-link-outline"
                      target="_blank"
                    >
                      Next school year’s pricing
                    </Link> */}
                    {/* <Link
                      to="https://mathfactlab.fillout.com/t/ciVZ2iE67Hus"
                      title="Student Login"
                      rel="noreferrer"
                      className="button-bg-green"
                      target="_blank"
                    > */}
                    <div
                      className="button-bg-green"
                      onClick={() => setIsOpen(true)}
                      // onClick={e => {
                      //   trackCustomEvent({
                      //     category: "Request a Quote",
                      //     action: "Click",
                      //     label: "Clicked on Request a Quote",
                      //   })
                      // }}
                    >
                      Request a Quote
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="bottom-section">
                    <div className="feature">
                      <span>as</span>
                    </div>
                    <div className="feature no-bg text-center">50 license minimum</div>
                    <div className="feature no-bg text-center">$3</div>
                    <div className="feature no-bg text-center vertical-align-center two-line">
                      Bulk & multi-year discounts available
                    </div>
                    <div className="feature no-bg text-center">All</div>
                    <div className="feature no-bg text-center vertical-align-center three-line">
                      Unlimited administrator and teacher profiles
                    </div>
                    <div className="feature no-bg text-center">Unlimited</div>
                    <div className="feature no-bg text-center vertical-align-center three-line">
                      Everything from Classroom Plan plus school and district reports
                    </div>
                    <div className="feature no-bg text-center vertical-align-center three-line">
                      Google Single Sign-On for admins, teachers and students
                    </div>
                    <div className="feature no-bg text-center">✔</div>
                    <div className="feature no-bg text-center">✔</div>

                    <div className="feature no-bg text-center">✔</div>

                    <div className="feature no-bg text-center">✔</div>

                    <div className="feature no-bg text-center">✔</div>

                    <div className="feature no-bg text-center">✔</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="spotlightInner-image mobile">
              <div className="grid-container">
                {/* ///111111 */}
                <div className="grid-item price-card-1 nohover mobile-card-3">
                  <div className="top-section"></div>

                  <div className="upper-section align-center">
                    <div className="price-card-title text-center">
                      <img src={familyImg} className="" alt="familyImg" />
                    </div>
                    <div className="price-card-title color-purple text-center">Family Plan</div>

                    <div className=" purple">
                      {" "}
                      <div className="text-center amount color-purple margin-top-bottom">
                        {" "}
                        {/* <span className="small-text">Free until July 5 </span> */}
                        $15/year
                      </div>
                    </div>

                    {/* <Link
                      to="https://mathfactlab.helpscoutdocs.com/article/79-pricing-as-of-july-2024"
                      title="Future Pricing"
                      className="text-center mb-30 font-15 a-link-outline"
                      target="_blank"
                    >
                      Learn more about future pricing.
                    </Link> */}
                    <Link
                      to="https://app.mathfactlab.com/signup"
                      title="Student Login"
                      rel="noreferrer"
                      className="button-bg-purple"
                    >
                      <div
                        onClick={e => {
                          trackCustomEvent({
                            category: "Coming soon button",
                            action: "Click",
                            label: "Clicked on coming soon",
                          })
                        }}
                      >
                        Start Today
                      </div>
                    </Link>
                  </div>
                  <div className="bottom-section">
                    <div className="feature">
                      <div className="text-center">
                        <b>Features</b>
                      </div>
                    </div>
                    <div className="feature no-bg text-center">
                      Number of Student Licenses Included
                      <br />
                      <b>1</b>
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Additional Licenses
                      <br />
                      <b>$5</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Additional Details
                      <br />
                      <b></b> <br />
                    </div>
                    <div className="feature no-bg text-center">
                      Available Student Levels
                      <br />
                      <b>All</b>
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Number of Adult Profiles
                      <br />
                      <b>1 parent profile</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Number of Classes
                      <br />
                      <b>1</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Reports
                      <br />
                      <b>Onscreen monitoring of student usage and progress</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Google Integration
                      <br />
                      <b> Parent Single Sign-On</b> <br />
                    </div>
                    {/* <div className="feature  no-bg text-center">
                      Shared Classes
                      <br />
                      <div className="font-15">(Multiple teachers can be provided access to the same class)</div>
                      <br />
                      <b>✔</b>
                    </div> */}

                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Teaching Tools</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Payment by Purchase Order</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Access for Co-Teachers</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Student Single Sign-On</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">ClassLink Integration</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature  no-bg text-center line-height-20">
                      Clever Integration
                      <br />
                      <div className="font-15">(With per school fee)</div>
                      <br />
                      <b>X</b>
                    </div>
                  </div>
                </div>
                {/* ///222222222 */}
                <div className="grid-item price-card-1 nohover mobile-card-1">
                  <div className="top-section"></div>

                  <div className="upper-section align-center">
                    <div className="price-card-title text-center">
                      <img src={teacherImg} className="" alt="teacherImg" />
                    </div>
                    <div className="price-card-title color-blue text-center">Teacher Plan</div>
                    {/* diagonal-line-text */}
                    <div className="blue">
                      <div className="text-center amount color-blue margin-top-bottom">
                        {" "}
                        {/* <span className="small-text">Free until August 1 </span> */}
                        $75/year
                      </div>
                    </div>
                    {/* <div className="text-center mb-22 line-height-28">
                      <b className="pre-student">Per Student / Per Year</b>
                      <br />
                      $50 for teachers <br />
                      paying out of pocket
                    </div> */}

                    {/* <Link
                      to="https://mathfactlab.helpscoutdocs.com/article/79-pricing-as-of-july-2024"
                      title="Future Pricing"
                      className="text-center mb-30 font-15 a-link-outline"
                      target="_blank"
                    >
                      Next school year’s pricing
                    </Link> */}

                    <Link
                      to="https://app.mathfactlab.com/signup"
                      title="Student Login"
                      rel="noreferrer"
                      className="button-bg-blue"
                    >
                      <div
                        onClick={e => {
                          trackCustomEvent({
                            category: "Coming soon button",
                            action: "Click",
                            label: "Clicked on coming soon",
                          })
                        }}
                      >
                        Start Today
                      </div>
                    </Link>
                  </div>
                  <div className="bottom-section">
                    <div className="feature">
                      <div className="text-center">
                        <b>Features</b>
                      </div>
                    </div>
                    <div className="feature no-bg text-center">
                      Number of Student Licenses Included
                      <br />
                      <b>50</b>
                    </div>

                    <div className="feature no-bg text-center long-text ">
                      Additional Licenses
                      <br />
                      <b>$1.50</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Additional Details
                      <br />
                      <b> 33% off for teachers paying out-of-pocket</b> <br />
                    </div>
                    <div className="feature no-bg text-center">
                      Available Student Levels
                      <br />
                      <b>All</b>
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Number of Adult Profiles
                      <br />
                      <b>1 teacher profile</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Number of Classes
                      <br />
                      <b>Up to 6</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Reports
                      <br />
                      <b>
                        {" "}
                        Everything from Family Plan plus downloadable individual and group reports (pdf or .csv)
                      </b>{" "}
                      <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Google Integration
                      <br />
                      <b>Teacher Single Sign-On and importing of class lists from Google Classroom</b> <br />
                    </div>
                    {/* <div className="feature  no-bg text-center">
                      Shared Classes
                      <br />
                      <div className="font-15">(Multiple teachers can be provided access to the same class)</div>
                      <br />
                      <b>✔</b>
                    </div> */}

                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Teaching Tools</div>
                      <br />
                      <b>✔</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Payment by Purchase Order</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Access for Co-Teachers</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Student Single Sign-On</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">ClassLink Integration</div>
                      <br />
                      <b>X</b>
                    </div>
                    <div className="feature  no-bg text-center line-height-20">
                      Clever Integration
                      <br />
                      <div className="font-15">(With per school fee)</div>
                      <br />
                      <b>X</b>
                    </div>
                  </div>
                </div>
                {/* 3333333 */}
                <div className="grid-item price-card-1 nohover mobile-card-2">
                  <div className="top-section"></div>
                  <div className="upper-section align-center">
                    <div className="price-card-title text-center">
                      <img src={schoolImg} className="" alt="schoolImg" />
                    </div>
                    <div className="price-card-title color-green text-center">School/District Plan</div>
                    <div className="text-center amount color-green margin-top-bottom">
                      {/* <span className="small-text">from </span> */}
                      $3/student
                    </div>
                    {/* <div className="text-center mb-22 line-height-28">
                      <b className="pre-student">Per Student / Per Year</b>
                      <br />
                      <ul className="bullet-list">
                        <li>$3 per additional student</li>
                        <li> Volume Discounts Available</li>
                      </ul>
                    </div> */}
                    {/* <Link
                      to="https://mathfactlab.helpscoutdocs.com/article/79-pricing-as-of-july-2024"
                      title="Future Pricing"
                      className="text-center mb-30 font-15 a-link-outline"
                      target="_blank"
                    >
                      Next school year’s pricing
                    </Link> */}
                    {/* <Link
                      to="https://form.jotform.com/232796840564164"
                      title="Student Login"
                      rel="noreferrer"
                      className="button-bg-green"
                      target="_blank"
                    > */}
                    <div
                      onClick={() => setIsOpen(true)}
                      className="button-bg-green"
                      // onClick={e => {
                      //   trackCustomEvent({
                      //     category: "Request a Quote",
                      //     action: "Click",
                      //     label: "Clicked on Request a Quote",
                      //   })
                      // }}
                    >
                      Request a Quote
                    </div>
                    {/* </Link> */}
                  </div>
                  <div className="bottom-section">
                    <div className="feature">
                      <div className="text-center">
                        <b>Features</b>
                      </div>
                    </div>

                    <div className="feature no-bg text-center">
                      Number of Student Licenses Included
                      <br />
                      <b>50 license minimum</b>
                    </div>

                    <div className="feature no-bg text-center long-text ">
                      Additional Licenses
                      <br />
                      <b>$3</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Additional Details
                      <br />
                      <b>Bulk & multi-year discounts available</b> <br />
                    </div>
                    <div className="feature no-bg text-center">
                      Available Student Levels
                      <br />
                      <b>All</b>
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Number of Adult Profiles
                      <br />
                      <b>Unlimited administrator and teacher profiles</b> <br />
                      {/* <b> teacher profiles</b> */}
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Number of Classes
                      <br />
                      <b>Unlimited</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Reports
                      <br />
                      <b> Everything from Classroom Plan plus school and district reports</b> <br />
                    </div>
                    <div className="feature no-bg text-center long-text ">
                      Google Integration
                      <br />
                      <b> Google Single Sign-On for admins, teachers and students</b> <br />
                    </div>
                    {/* <div className="feature  no-bg text-center">
                      Shared Classes
                      <br />
                      <div className="font-15">(Multiple teachers can be provided access to the same class)</div>
                      <br />
                      <b>✔</b>
                    </div> */}

                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Teaching Tools</div>
                      <br />
                      <b>✔</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Payment by Purchase Order</div>
                      <br />
                      <b>✔</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Access for Co-Teachers</div>
                      <br />
                      <b>✔</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">Student Single Sign-On</div>
                      <br />
                      <b>✔</b>
                    </div>
                    <div className="feature no-bg text-center line-height-15">
                      <div className="mb-6">ClassLink Integration</div>
                      <br />
                      <b>✔</b>
                    </div>
                    <div className="feature  no-bg text-center line-height-20">
                      Clever Integration
                      <br />
                      <div className="font-15">(With per school fee)</div>
                      <br />
                      <b>✔</b>
                    </div>
                  </div>
                </div>
                {/* 333333333333 */}
                {/* <div className="grid-item price-card-1 nohover mobile-card-3">
                  <div className="upper-section">
                    <div className="price-card-title">
                      <span>Pricing Calculator</span>
                    </div>
                    <div className="switch-chip">
                      <span>
                        {isSelectStudent ? <b>Teacher/Parents</b> : " Teacher/Parents"}

                        <label className="switch">
                          <input type="checkbox" onChange={handleSelectUserType} checked={isSelectStudent} />
                          <span className="slider round"></span>
                        </label>
                        {isSelectStudent ? "School" : <b>School</b>}
                      </span>
                    </div>
                    <div>
                      <div className="mb-6">
                        <b>Number of Students</b>
                      </div>
                      <div className="counter-grid-container max-w-250">
                        <div className="right-counter" onClick={() => handleChangeStudentCount(1)}>
                          <b>+</b>
                        </div>
                        <div className="count">
                          <input
                            value={numberOfStudent}
                            type="number"
                            onChange={e => handleChangeStdInputTextCount(e)}
                          />
                        </div>
                        <div className="left-counter" onClick={() => handleChangeStudentCount(-1)}>
                          <b>-</b>
                        </div>
                      </div>
                    </div>
                    <div style={{ visibility: !isSelectStudent ? "hidden" : "initial" }}>
                      <div className="mb-6">
                        <b>Number of Years</b>
                      </div>
                      <div className="counter-grid-container  max-w-250">
                        <div className="right-counter" onClick={() => handleChangeYearCount(1)}>
                          <b>+</b>
                        </div>
                        <div className="count">
                          {" "}
                          <input value={numberOfYear} type="number" onChange={e => handleChangeYearInputTextCount(e)} />
                        </div>
                        <div className="left-counter" onClick={() => handleChangeYearCount(-1)}>
                          <b>-</b>
                        </div>
                      </div>
                    </div>
                    <div className="total-section  max-w-250">
                      <div>Total</div>
                      <div>
                        <b>
                          $
                          {isSelectStudent
                            ? numberOfStudent * numberOfYear * 3 <= 1500
                              ? numberOfStudent * numberOfYear * 3
                              : 1500
                            : 0}
                        </b>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="label-section-wrapper">
        <div className="round-label">
          <Link to="/contact-us/" title="Contact us pricing" rel="noreferrer" className="label-text bg-blue">
            <div>Got questions? Please contact us.</div>
          </Link>{" "}
        </div>

        <div className="round-label">
          <Link
            to={"https://calendly.com/mathfactlab/30min"}
            target="_blank"
            title="Contact us pricing"
            rel="noreferrer"
            className="label-text bg-green"
          >
            <div>Schedule a meeting to learn more.</div>
          </Link>{" "}
        </div>
      </div>

      <section className="mfl-accordions-section section price-faq-section">
        <div className="container">
          <div className="row mfl-accordions-row">
            <div className="col-xs-12">
              {/* <h3 className="title">FAQs</h3> */}
              <div className="mfl-accordions-wrapper">
                <h3 className="accordion-title">Why MathFactLab?</h3>
                <div className={`mfl-accordions-item ${selectedQuestionIndex === 1 ? "active" : ""}`}>
                  <a
                    className="mfl-accordions-link"
                    onClick={() => setSelectedQuestionIndex(selectedQuestionIndex === 1 ? 0 : 1)}
                    title="MathFactLab f1"
                  >
                    <img src={arrowRight} alt="arrowRight" className="mfl-acc-icon" /> Why is MathFactLab better than
                    other math fact fluency programs or websites?
                  </a>
                  <div className="mfl-accordions-answer">
                    {/* <p className="mfl-answer-item">
                    Aliquam bibendum pulvinar felis, a molestie orci venenatis quis. Cras vulputate consequat malesuada.
                    Cras magna ante, accumsan in elit vitae, luctus varius magna. Sed maximus, libero vel pretium
                    malesuada, nulla leo aliquet nibh, eget hendrerit orci odio quis tellus. Cras in ligula dictum,
                    dapibus lectus non, commodo enim.
                  </p> */}
                    <p className="mfl-bullet-item">
                      Our founder developed MathFactLab because he could not find a true math fact fluency program on
                      the internet. While the others may claim to develop fluency, they are, at best, only drilling for
                      automaticity through memorization.
                    </p>
                    <p className="mfl-bullet-item">
                      Automaticity is just automatic recall, like knowing your phone number; it requires no
                      understanding. Fluency, on the other hand, is flexible, efficient and based on a foundation of
                      reasoning and understanding.
                    </p>
                    <p className="mfl-bullet-item">
                      Fluency can’t be developed through rote memorization and drill, which is the approach used by our
                      competitors. Students develop true math fact fluency when they explore the basic math facts
                      through a variety of strategies, continuously being challenged to find the interconnections and
                      relationship between the various facts and their inverse operations. MathFactLab was built to do
                      just this.
                    </p>
                  </div>
                </div>
                <div className={`mfl-accordions-item ${selectedQuestionIndex === 2 ? "active" : ""}`}>
                  <a
                    className="mfl-accordions-link"
                    onClick={() => setSelectedQuestionIndex(selectedQuestionIndex === 2 ? 0 : 2)}
                    title="MathFactLab f2"
                  >
                    <img src={arrowRight} alt="arrowRight" className="mfl-acc-icon" /> Who created MathFactLab and why?
                  </a>
                  <div className="mfl-accordions-answer">
                    {/* <p className="mfl-answer-item">
                    Aliquam bibendum pulvinar felis, a molestie orci venenatis quis. Cras vulputate consequat malesuada.
                    Cras magna ante, accumsan in elit vitae, luctus varius magna. Sed maximus, libero vel pretium
                    malesuada, nulla leo aliquet nibh, eget hendrerit orci odio quis tellus. Cras in ligula dictum,
                    dapibus lectus non, commodo enim.
                  </p> */}
                    <p className="mfl-bullet-item">
                      MathFactLab was created by Mike Kenny, a working fifth-grade math, science and social studies
                      teacher. The idea for MathFactLab sprang from a master’s project completed when he was a student
                      in the Vermont Mathematics Initiative.
                    </p>
                    <p className="mfl-bullet-item">
                      He had been frustrated by the absence of any commercially-available math fact program that
                      actually aligned with the research, so, for his project, he developed a large set of
                      strategy-based flashcards for his students to use as they practiced their math facts.
                    </p>
                    <p className="mfl-bullet-item">
                      As there were so many flashcards, the students struggled to keep the sets organized in a way that
                      was effective, so Mike eventually moved the program online using Google Slideshows. This made a
                      huge improvement and Mike began to truly see the effectiveness of providing a range of strategies
                      for students to develop true math fact fluency.
                    </p>
                    <p className="mfl-bullet-item">
                      Google Slides, however, lacked the interactivity and memory of a true webapp, so after seeing
                      first hand the potential of this strategy-based approach, Mike began the long process of building
                      a website, with the hope that this approach could be used not only by his students and those in
                      his school, but by students around the world.
                    </p>
                  </div>
                </div>
                <div className={`mfl-accordions-item ${selectedQuestionIndex === 2.1 ? "active" : ""}`}>
                  <a
                    className="mfl-accordions-link"
                    onClick={() => setSelectedQuestionIndex(selectedQuestionIndex === 2.1 ? 0 : 2.1)}
                    title="MathFactLab f2"
                  >
                    <img src={arrowRight} alt="arrowRight" className="mfl-acc-icon" /> Do you offer trainings for
                    teachers or help getting started?
                  </a>
                  <div className="mfl-accordions-answer">
                    <p className="mfl-bullet-item">
                      Yes, Mike Kenny, MathFactLab’s creator, is happy to meet with teachers via Zoom to help them get
                      the most out of MathFactLab.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpen && <FilloutPopupEmbed filloutId="ciVZ2iE67Hus" onClose={() => setIsOpen(false)} />}
    </Layout>
    // </Suspense>
  )
}

export default Price
